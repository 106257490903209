(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Embeded modal
     * -- Modal for generate the embeded map
     *
     * Usages:
     *    - share-modal.js
     *
     * Dependencies:
     *    - could contain traces of bootstrap
     */
    Vue.component("embeded-modal", {
        props: ["isModalOpen", "title", "url", "i18n", "trailId", "subdomain"],
        data: function () {
            return {
                showOptions: false,
                showElevation: false,
                showImages: false,
                maptype: "H",
                tempWidth: "600",
                tempHeight: "500",
                width: "600",
                height: "500",
            };
        },
        template: `
    <div v-if="isModalOpen" class="embeded-modal--mask"
    aria-labelledby="Related trails to the main trail"
     aria-modal="true"
     :aria-hidden="isModalOpen"
     ref="embededModal"
     v-cloak>
      <div class="embeded-modal__wrapper">
        <div class="embeded-modal__content" id="embeded-modal" ref="embededModalContent">
          <div class="embeded-modal__close">
          <h2 class="embeded-modal__title">{{ i18n.txtDisplayTrailOnYourSite }}</h2>
          <button type="button" class="embeded-modal__close-button" aria-label="Close" @click="close">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg"/>
          </button>
         </div>
         <div class="embeded-modal__wrapper-content-scroll">
           <div class="embeded-modal__wrapper-content">
             <a @click="close" href="#" class="embeded-modal__back wllink-simple">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                  <path d="M8 13L2 7L8 1" stroke="#4C8C2B" stroke-width="2"/>
                  <line x1="2" y1="7" x2="16" y2="7" stroke="#4C8C2B" stroke-width="2"/>
                </svg>
                {{i18n.txtEmbedBack}}
            </a>
            <p class="embeded-modal__howto">{{i18n.txtHowToHtml}}</p>
             
           <div class="embeded-modal__code">
              <textarea :value="iframe" readonly></textarea>
              <button @click="copyUrl" class="wlbutton wlbutton--ter-grey"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/copylink.svg"/>{{i18n.txtEmbedCopyCode}}</button>
            </div>
             
             
                <p v-if="!showOptions" @click="enableOptions" class="embeded-modal__wrapper-content__options wllink-simple">
                {{i18n.txtShowOptions}}&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                    <path d="M0.504395 0.821289L5.96484 6.28174L11.4253 0.821289" stroke="#4C8C2B"/>
                  </svg>
                </p>
                <p v-if="showOptions" @click="hideOptions" class="embeded-modal__wrapper-content__options wllink-simple">
                {{i18n.txtHideOptions}}&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                    <path d="M11.8232 6.78174L6.36279 1.32129L0.902344 6.78174" stroke="#4C8C2B"/>
                  </svg>
                </p>
              <transition name="embeded-modal__wrapper-content__fade">
                 <div v-if="showOptions" class="embeded-modal__wrapper-content__config">
                    <div class="embeded-modal__wrapper-content__form-group">
                       <div class="embed-map-control embed-map-type-control">
                          <label>{{i18n.txtMapType}}</label>
                          <select id="embed-map-type" name="embed-map-type" class="wlselector wlselector--lg" v-model="maptype"> 
                             <option value="H" selected="selected">{{i18n.txtMapTypeSatellite}}</option>
                             <option value="M">{{i18n.txtMapTypeMap}}</option>
                             <option value="T">{{i18n.txtMapTypeTerrain}}</option>
                          </select>
                       </div>
                    </div>
                    <div class="embeded-modal__wrapper-content__form-group embeded-modal__wrapper-content__form-group--row">
                       <div class="embed-map-control embed-map-size-control">
                          <label>{{i18n.txtMapWidth}}</label> 
                          <input type="text" id="embed-code-width" class="embeded-modal__wrapper-content__input embeded-modal__wrapper-content__input-small" maxlength="4" value="500" data-attr="tempWidth" v-model="tempWidth" @blur="persistWidth">
                        </div>
                        <div class="embed-map-control embed-map-size-control">
                          <label>{{i18n.txtMapHeight}}</label> 
                          <input type="text" id="embed-code-height" class="embeded-modal__wrapper-content__input embeded-modal__wrapper-content__input-small" maxlength="4" value="400" data-attr="tempHeight" v-model="tempHeight" @blur="persistHeight">
                       </div>
                    </div>
                    <div class="embeded-modal__wrapper-content__form-group">
                       <div class="embeded-modal__wrapper-content__checkbox">
                           <label class="checkbox-wikiloc">
                              <input type="checkbox" v-model="showElevation">
                              <span class="checkbox-wikiloc__mark checkbox-wikiloc__mark--big"></span>
                              <p>{{i18n.txtEmbedShowElevation}}</p>
                          </label>
                       </div>
                       <div class="embeded-modal__wrapper-content__checkbox">
                          <label class="checkbox-wikiloc">
                              <input type="checkbox" v-model="showImages">
                              <span class="checkbox-wikiloc__mark checkbox-wikiloc__mark--big"></span>
                              <p>{{i18n.txtShowPhotos}}</p>
                          </label>
                       </div>
                    </div>
    
                    
                 </div>
               </transition>
            </div>
            
            <div class="embeded-modal__map">
              <p>{{i18n.txtEmbedWillLook}}</p>
              <div class="embeded-modal__map__iframe" v-html="iframe">
              </div>
            </div>
          </div>
          
            <p class="embeded-modal__footer" v-html="i18n.txtEmbedTerms"></p>
        </div>
       </div>
     </div>
`,
        methods: {
            close: close,
            onClickOutside: onClickOutside,
            enableOptions: enableOptions,
            hideOptions: hideOptions,
            copyUrl: copyUrl,
            persistWidth: persistWidth,
            persistHeight: persistHeight,
        },
        computed: {
            iframe: iframe,
            terms: terms,
        },
        watch: {
            isModalOpen: isModalOpen,
        },
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */

    function iframe() {
        var elevation = this.showElevation ? "on" : "off";
        var images = this.showImages ? "on" : "off";
        return (
            '<iframe frameBorder="0" scrolling="no" ' +
            'src="https://' +
            this.subdomain +
            ".wikiloc.com/wikiloc/embedv2.do?id=" +
            this.trailId +
            "&elevation=" +
            elevation +
            "&images=" +
            images +
            "&maptype=" +
            this.maptype +
            '" ' +
            'width="' +
            this.width +
            '" height="' +
            this.height +
            '"></iframe>' +
            '<div style="color:#777;font-size:11px;line-height:16px;">' +
            this.i18n.txtPoweredBy +
            '&nbsp;<a style="color:#4C8C2B;font-size:11px;line-height:16px;" target="_blank" href="https://' +
            this.subdomain +
            '.wikiloc.com">Wikiloc</a></div>'
        );
    }

    function terms() {
        if (
            this.subdomain === "ca" ||
            this.subdomain === "es" ||
            this.subdomain === "gl" ||
            this.subdomain === "eu"
        ) {
            return "https://www.wikiloc.com/wikiloc/terms_es.html?v5.1";
        } else {
            return "https://www.wikiloc.com/wikiloc/terms_en.html?v5.1";
        }
    }

    function close() {
        this.$emit("closeEmbededModalEvent", {});
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();

        const el = this.$refs.embededModalContent;
        if (el && !el.contains(event.target)) {
            this.$emit("closeEmbededModalEvent", {});
        }
    }

    function enableOptions() {
        this.showOptions = true;
    }

    function hideOptions() {
        this.showOptions = false;
    }

    function copyUrl() {
        const el = document.createElement("textarea");
        el.value = this.iframe;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    /**
     * If you change the minimum and maximum values of widht/height,
     * change it also on the embedV2.css min/max map size.
     */
    function persistWidth() {
        this.tempWidth = Math.min(Math.max(this.tempWidth, 300), 1600);
        this.width = this.tempWidth;
    }

    function persistHeight() {
        this.tempHeight = Math.min(Math.max(this.tempHeight, 300), 1000);
        this.height = this.tempHeight;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
            } else {
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.embededModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
})();
